<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12"
                lg="12"
                md="12"
                sm="12"
            >
                <h3>Descontos</h3>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <hr />
            </v-col>
        </v-row>

        <v-row v-if="id != 0">
            <v-col
                cols="12"
                lg="3"
                md="6"
                sm="12"
            >
                <label>Produto</label>
                <v-text-field
                    v-model="descontoRequest.descricaoProduto"
                    disabled
                    outlined
                >
                </v-text-field>
            </v-col>
            <v-col
                cols="12"
                lg="2"
                md="6"
                sm="12"
            >
                <label>Tipo Cliente</label>
                <v-combobox
                    v-model="descontoRequest.tipoClienteSelected"
                    :items="listTipoCliente"
                    :rules=[validations.required]
                    item-text="descricao"
                    item-value="id"
                    clearable
                    outlined
                ></v-combobox>
            </v-col>
            <v-col
                cols="12"
                lg="2"
                md="6"
                sm="12"
            >
                <label>A partir de quantas peças?</label>
                <v-text-field
                    v-model="descontoRequest.qtdePecasLimite"
                    v-mask="'#####'"
                    :rules=[validations.required]
                    outlined
                >
                </v-text-field>
            </v-col>
            <v-col
                cols="12"
                lg="2"
                md="6"
                sm="12"
            >
                <label>Desconto Base</label>
                <v-text-field
                    v-model="descontoRequest.descontoBase"
                    suffix=" %"
                    v-formatMoney="percFormat"
                    :rules=[validations.required]
                    outlined
                >
                </v-text-field>
            </v-col>
            <v-col
                cols="12"
                lg="3"
                md="6"
                sm="12"
            >
                <label>Desconto</label>
                <v-text-field
                    v-model="descontoRequest.desconto"
                    suffix=" %"
                    v-formatMoney="percFormat"
                    :rules=[validations.required]
                    outlined
                >
                </v-text-field>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col
                cols="4"
                style="padding-left: 0px !important;"
            >
                <v-card
                    class="mx-auto"
                    flat
                >
                    <v-card-title class="primary white--text text-h5" style="border-bottom: solid 1px var(--color__cinza)">
                        Família
                    </v-card-title>
                    <v-card-text style="margin-top: 20px;">
                        <v-treeview
                            selectable
                            return-object
                            v-model="modelTreeView"
                            :items="listTreeView"
                            :load-children="carregarItens"
                            activatable
                            rounded
                            open-on-click
                            transition
                        >
                            <template v-slot:label="{ item }">
                                <v-row>
                                    <v-col
                                        cols="9"
                                    >
                                        <span :style="item.tipo == 'Grandeza' || item.tipo == 'Marca' || item.tipo == 'TipoProduto' ? 'color: var(--color__cinza_escuro);' : 'color: var(--color__main);'"> 
                                            <strong>[{{item.tipo}}]</strong> - {{ item.name }} 
                                        </span>
                                    </v-col>
                                </v-row>
                            </template>
                        </v-treeview>
                    </v-card-text>
                </v-card>
            </v-col>
    
            <v-divider vertical></v-divider>
            
            <v-col
                class="d-flex text-center"
            >
                <v-scroll-y-transition mode="out-in">
                    <v-card
                        class="mx-auto"
                        style="width: 100%;"
                        flat
                    >
                        <v-card-title class="primary white--text text-h5" style="border-bottom: solid 1px var(--color__cinza)">
                            Informações do Desconto
                        </v-card-title>

                        <v-divider></v-divider>

                        <v-row
                            class="text-left"
                            tag="v-card-text"
                            style="height: 0px;"
                        >
                            <v-col
                                cols="4"
                                lg="4"
                                md="4"
                                sm="4"
                            >
                                <label>Tipo Cliente</label>
                            </v-col>
                            <v-col
                                cols="2"
                                lg="2"
                                md="2"
                                sm="2"
                            >
                                <label>Desconto Base</label>
                            </v-col>
                            <v-col
                                cols="2"
                                lg="2"
                                md="2"
                                sm="2"
                            >
                                <label>A partir de quantas peças?</label>
                            </v-col>
                            <v-col
                                cols="2"
                                lg="2"
                                md="2"
                                sm="2"
                            >
                                <label>Desconto?</label>
                            </v-col>
                        </v-row>

                        <v-row
                            v-for="(itemDesconto, indexDesconto) in listDescontos"
                            class="text-left"
                            tag="v-card-text"
                            :key="indexDesconto"
                            style="height: 80px;padding-bottom: 10px;"
                        >
                            <v-col
                                cols="4"
                                lg="4"
                                md="4"
                                sm="4"
                            >
                                <v-combobox
                                    v-model="itemDesconto.tipoClienteSelected"
                                    :items="listTipoCliente"
                                    :rules=[validations.required]
                                    item-text="descricao"
                                    item-value="id"
                                    clearable
                                    outlined
                                ></v-combobox>
                            </v-col>
                            <v-col
                                cols="2"
                                lg="2"
                                md="2"
                                sm="2"
                            >
                                <v-text-field
                                    v-model="itemDesconto.descontoBase"
                                    suffix=" %"
                                    v-formatMoney="percFormat"
                                    :rules=[validations.required]
                                    outlined
                                >
                                </v-text-field>
                            </v-col>
                            <v-col
                                cols="2"
                                lg="2"
                                md="2"
                                sm="2"
                            >
                                <v-text-field
                                    v-model="itemDesconto.qtdePecasLimite"
                                    v-mask="'#####'"
                                    :rules=[validations.required]
                                    outlined
                                >
                                </v-text-field>
                            </v-col>
                            <v-col
                                cols="2"
                                lg="2"
                                md="2"
                                sm="2"
                            >
                                <v-text-field
                                    v-model="itemDesconto.desconto"
                                    suffix=" %"
                                    v-formatMoney="percFormat"
                                    :rules=[validations.required]
                                    outlined
                                >
                                </v-text-field>
                            </v-col>
                            <v-col 
                                cols="2" 
                                :class="itemDesconto.showAddNewItem != true ? 'colButtonList' : 'colButtonList2'"
                            >
                                <v-tooltip top v-if="itemDesconto.showAddNewItem == true" style="padding-bottom: 5px;">
                                    <template v-slot:activator="{ on }">
                                        <v-btn 
                                            class="btnActions"
                                            outlined
                                            fab
                                            x-small
                                            v-on="on"
                                            @click="addNewItemDesconto(itemDesconto)" 
                                            color="blue" 
                                            small
                                            :key="indexDesconto"
                                        >
                                            <v-icon>mdi-plus</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Adicionar Novo Item</span>
                                </v-tooltip>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-btn 
                                            class="btnActions"
                                            outlined
                                            fab
                                            x-small
                                            v-on="on"
                                            @click="deleteItemDesconto(itemDesconto)" 
                                            color="red" 
                                            small
                                            :disabled="listDescontos.length <= 1"
                                        >
                                            <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Excluir Item</span>
                                </v-tooltip>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-scroll-y-transition>
            </v-col>
        </v-row>
        
        <br />

        <ActionButtons
            :request="{}"
            :loading="loading"
            :showButtons="{
                cancel: true,
                save: true
            }"
            @cancel="cancel"
            @save="confirmSave"
        />

        <ActionDialog 
            v-on:methodConfirmToCall="dialog.methodConfirm"
            :showDialog.sync="dialog.show"
            :headerTitle="dialog.headerText"
            :bodyText="dialog.bodyText"
            :params="dialog.params"
        />

    </v-container>
</template>

<script>
    import { required } from "@/utilities/Rules";
    import Helpers from '@/utilities/Helpers';
    import ActionButtons from "@/components/Layout/ActionButtons";
    import ActionDialog from "@/components/Layout/ActionDialog";
    import { formaterDecimalBRServer } from '@/utilities/Utils';

    const pause = ms => new Promise(resolve => setTimeout(resolve, ms))

    export default ({

        mixins: [Helpers],

        components: {
            ActionButtons,
            ActionDialog: ActionDialog
        },

        data: () => ({

            descontoRequest: {},

            listTipoCliente: [
                { id: 1, descricao: 'OEM'},
                { id: 2, descricao: 'Distribuidor'},
                { id: 3, descricao: 'Usuario Final'},
                { id: 4, descricao: 'Revenda'},
            ],

            modelTreeView: [],
            listTreeView: [],
            listDescontos: [{
                id: Math.random(),
                tipoClienteSelected: {
                    id: 1,
                    descricao: 'OEM'
                },
                qtdePecasLimite: 1,
                descontoBase: "0,00",
                desconto: "0,00",
                showAddNewItem: true
            }],

            percFormat: {
                suffix: '',
                thousands: '',
                decimal: ',',
                precision: 2,
            },

            loading: false,
            
            validations: {
                required: required,
            },

            dialog: {
                show: false,
                headerText: "",
                bodyText: "",
                methodConfirm: ( () => {} ),
                params: null
            },

        }),

        props: {
            id: {
                default: 0
            }
        },

        methods: {
            
            async getRegister() {

                if (this.id !== 0) {

                    let response = await this.$store.dispatch("descontoModule/GetById", this.id);

                    if (response.success) {
                        this.descontoRequest = response.result;

                        this.descontoRequest.desconto = this.descontoRequest.descontoFormatted;
                        this.descontoRequest.descontoBase = this.descontoRequest.descontoBaseFormatted;
                        this.descontoRequest.tipoClienteSelected = {
                            id: this.descontoRequest.tipoCliente,
                            descricao: this.descontoRequest.tipoClienteDescricao,
                        }
                    }
                }
                else {
                    this.descontoRequest.status = 1;
                }
            },

            async carregarItens(item) {

                if (item.tipo == 'Grandeza') {
                    let listMarca = await this.$store.dispatch("produtoModule/ListTreeView", { idGrandeza: item.idTable, idMarca: 0, idTipoProduto: 0 });
                    item.children = listMarca;
                }

                if (item.tipo == 'Marca') {
                    let listTipoTipo = await this.$store.dispatch("produtoModule/ListTreeView", { idGrandeza: 0, idMarca: item.idTable, idTipoProduto: 0 });
                    item.children = listTipoTipo;
                }

                if (item.tipo == 'TipoProduto') {
                    let listProdutos = await this.$store.dispatch("produtoModule/ListTreeView", { idGrandeza: 0, idMarca: 0, idTipoProduto: item.idTable });
                    item.children = listProdutos;
                    listProdutos.forEach (itemProduto => {
                        itemProduto.children = undefined
                    })
                }

                if (item.tipo != 'Produto') {
                    await pause(100)
                }
            },            

            async addNewItemDesconto(itemDesconto) {

                if (itemDesconto.qtdePecasLimite == null || itemDesconto.qtdePecasLimite == undefined || itemDesconto.qtdePecasLimite == 0 ||
                    itemDesconto.desconto == null || itemDesconto.desconto == undefined || itemDesconto.desconto == 0 ||
                    itemDesconto.tipoClienteSelected == null || itemDesconto.tipoClienteSelected == undefined) {
                    this.showToast("error", "Aviso!", "Todos os campos são obrigatórios!");
                    return false;
                }
                else {

                    var existeItemDesconto = this.listDescontos.filter(
                        des => des.tipoClienteSelected.id == itemDesconto.tipoClienteSelected.id && 
                        des.qtdePecasLimite == itemDesconto.qtdePecasLimite && 
                        des.desconto == itemDesconto.desconto);

                    if (existeItemDesconto != null && existeItemDesconto != undefined && existeItemDesconto.length > 1) {
                        this.showToast("error", "Aviso!", "Esse desconto já foi adicionado!");
                    }
                    else {

                        if (itemDesconto !== null && itemDesconto !== undefined) {
                            itemDesconto.showAddNewItem = false;
                        }

                        this.listDescontos.push({
                            id: Math.random(),
                            tipoClienteSelected: {
                                id: 1,
                                descricao: 'OEM'
                            },
                            qtdePecasLimite: 1,
                            descontoBase: "0,00",
                            desconto: "0,00",
                            showAddNewItem: true
                        })
                    }
                }
            },            
            
            async deleteItemDesconto(itemDesconto) {

                let index = 0;

                this.listDescontos.forEach(des => {
                    if (des.id == itemDesconto.id) {

                        this.listDescontos.splice(index, 1);
                    }
                    index++
                })
                
                let lastIndex = this.listDescontos.length - 1;

                this.listDescontos[lastIndex].showAddNewItem = true;
            },

            async getLists() {
                
                this.listTreeView = await this.$store.dispatch("produtoModule/ListTreeView", { idGrandeza: 0, idMarca: 0, idTipoProduto: 0 });
            },

            async cancel() {
                this.$router.push({ path: "/admin/descontoList" });
            },

            async confirmSave() {

                if (this.id != 0) {
                    this.save();
                }
                else {
                    this.dialog = {
                        show: true,
                        headerText: 'Confirmação',
                        bodyText: 'Confirma a criação desses DESCONTOS no sistema?',
                        methodConfirm: this.save,
                        params: 0
                    };
                }
            },

            async save() {

                if (this.id != 0) {

                    this.descontoRequest.id = this.id;

                    if (this.descontoRequest.tipoClienteSelected == null || this.descontoRequest.tipoClienteSelected == undefined) {
                        this.showToast("error", "Aviso!", "Tipo de Cliente é obrigatório");
                    }
                    else if (this.descontoRequest.qtdePecasLimite == null || this.descontoRequest.qtdePecasLimite <= 0) {
                        this.showToast("error", "Aviso!", "Qtde de Peças é obrigatório");
                    }
                    else if (this.descontoRequest.desconto == null || this.descontoRequest.desconto <= 0) {
                        this.showToast("error", "Aviso!", "Desconto é obrigatório");
                    }
                    else {

                        let descontoRequest = {
                            id: this.id,
                            tipoCliente: this.descontoRequest.tipoClienteSelected.id,
                            idTable: this.descontoRequest.idProduto,
                            qtdePecasLimite: parseInt(this.descontoRequest.qtdePecasLimite),
                            descontoBase: formaterDecimalBRServer(this.descontoRequest.descontoBase),
                            desconto: formaterDecimalBRServer(this.descontoRequest.desconto),
                        }
                        
                        const result = await this.$store.dispatch("descontoModule/Update", descontoRequest);

                        if (result.success === true) {
                            this.showToast("success", "Sucesso!", result.message);
                            this.cancel();
                        }
                        else {
                            this.showToast("error", "Aviso!", result.message);
                        }

                        this.loading = false;
                        this.hideLoading();
                    }
                }
                else {

                    if (this.modelTreeView.length <= 0) {
                        this.showToast("error", "Aviso!", "É necessário selecionar pelo um item da árvore [Grandeza], [Marca] ou [Produto]!");
                    }
                    else {
                        this.loading = true;
                        this.showLoading();

                        this.descontoRequest = [];

                        this.listDescontos.forEach(itemDesconto => {

                            let qtdePecasLimite = parseInt(itemDesconto.qtdePecasLimite)
                            let descontoBase = formaterDecimalBRServer(itemDesconto.descontoBase)
                            let desconto = formaterDecimalBRServer(itemDesconto.desconto)
                            
                            if (itemDesconto.tipoClienteSelected != null && itemDesconto.tipoClienteSelected != undefined && qtdePecasLimite >= 1) {
                                this.modelTreeView.forEach(itemTreeView => {

                                    this.descontoRequest.push({
                                        id: 0,
                                        tipo: itemTreeView.tipo,
                                        idTable: itemTreeView.idTable,
                                        tipoCliente: itemDesconto.tipoClienteSelected.id,
                                        qtdePecasLimite: qtdePecasLimite,
                                        descontoBase: descontoBase,
                                        desconto: desconto,
                                    })
                                })
                            }
                        });

                        if (this.descontoRequest.length > 1000) {

                            let descontoRequest = [];
                            let qtdeCopia = 1000;
                            let posInicial = 0;
                            let posFinal = qtdeCopia;
                            
                            this.showLoading();

                            while (posFinal < this.descontoRequest.length) {
                                descontoRequest = [...this.descontoRequest.slice(posInicial, posFinal)];

                                this.saveDesconto(descontoRequest, false);

                                posInicial += qtdeCopia;
                                posFinal += qtdeCopia;

                                if (posFinal > this.descontoRequest.length) {
                                    posFinal = this.descontoRequest.length;
                                }
                            }

                            this.hideLoading();
                            this.showToast("success", "Sucesso!", "Descontos salvos com sucesso!");
                            this.cancel();
                        }
                        else {
                            this.saveDesconto(this.descontoRequest, true);
                        }

                        this.loading = false;
                        this.hideLoading();
                    }
                }
            },

            async saveDesconto(descontoRequest, mostraMsg) {
                const result = await this.$store.dispatch("descontoModule/Create", descontoRequest);

                if (mostraMsg) {
                    if (result.success === true) {
                        this.showToast("success", "Sucesso!", result.message);
                        this.cancel();
                    }
                    else {
                        this.showToast("error", "Aviso!", result.message);
                    }
                }
            }
        },

        created() {
            this.showLoading();

            this.getLists();
            this.getRegister();

            this.hideLoading();
        }
    })
</script>

<style scoped>
    .colButtonList {
        padding-top: 20px;    
    }

    .colButtonList2 {
        margin-top: 8px;
    }

    @media only screen and (max-width: 647px) {
        .colButtonList {
            margin-top: -30px;
            display: flex;
            align-items: center;
        }

        .colButtonList2 {
             margin-top: -8px;
        }

        .btnActions {
            margin-bottom: 5px;
        }
    }
</style>